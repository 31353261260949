import { useState } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import logo from './logo.gif';
import cover from './cover.jpg';
import './App.css';

function App() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div id="app">
      <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="https://xosodientoan.info/" className="-m-1.5 p-1.5 logo-wrap">
              <span className="sr-only">Jateng Toto</span>
              <img className="h-12 w-auto" src={logo} alt="JatengToto Site" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-300" onClick={() => setMobileMenuOpen(true)}>
              <span className="sr-only">Open main menu</span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-6 w-6" viewBox="0 0 16 16" aria-hidden="true">
                <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:justify-end">
            <a href="https://rebrand.ly/skh2s8h?pg=register" className="px-5 py-2 text-sm font-medium text-center inline-flex items-center text-neutral-900 rounded-lg bg-gradient-to-br from-amber-500 to-amber-300 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-yellow-300 focus:ring-4 focus:outline-none focus:ring-yellow-300">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-3 h-3 me-1 text-neutral-900" viewBox="0 0 16 16">
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
              </svg>
              Daftar
            </a>
            <a href="https://rebrand.ly/skh2s8h" className="px-4 py-2 ms-2 text-sm font-medium text-center inline-flex items-center text-white rounded-lg bg-gradient-to-br from-red-900 to-red-700 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-orange-300 focus:ring-4 focus:outline-none focus:ring-orange-300">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-3 h-3 me-1 text-white" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"/>
                <path fillRule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
              </svg>
              Login
            </a>
          </div>
        </nav>
        <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-slate-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="https://xosodientoan.info/" className="-m-1.5 p-1.5">
                <span className="sr-only">Jateng Toto</span>
                <img className="h-12 w-auto" src={logo} alt="JatengToto Site" />
              </a>
              <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-500" onClick={() => setMobileMenuOpen(false)}>
                <span className="sr-only">Close menu</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="h-6 w-6" aria-hidden="true" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
              </button>
            </div>
            <div className="mt-8 flow-root">
              <div className="-my-6 divide-y divide-slate-100/30">
                <div className="space-y-2 py-6">
                  <a href="https://rebrand.ly/skh2s8h?pg=rules-game" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-slate-300 hover:bg-slate-900">
                    Panduan Cara Bermain
                  </a>
                  <a href="https://rebrand.ly/skh2s8h?pg=promotion" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-slate-300 hover:bg-slate-900">
                    Informasi Promo Eklusif
                  </a>
                </div>
                <div className="py-6">
                  <a href="https://rebrand.ly/skh2s8h?pg=register" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-slate-300 hover:bg-slate-700">
                    Daftar Member JatengToto
                  </a>
                  <a href="https://rebrand.ly/skh2s8h?pg=register" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-slate-300 hover:bg-slate-700">
                    Log-In Member JatengToto
                  </a>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>

      <div className="ab ec adn aje px-6 pt-14 lg:px-8 min-h-screen">
        <svg className="aa ak ed pn tu aqe bfe" aria-hidden="true"><defs><pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse"><path d="M.5 200V.5H200" fill="none"></path></pattern></defs><svg x="50%" y="-1" className="ado aow"><path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z" strokeWidth="0"></path></svg><rect width="100%" height="100%" strokeWidth="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"></rect></svg>
        <div className="aa cy dq ed xp bec bui csi csy dij" aria-hidden="true"><div className="mg tj ams amw anv bbf" style={{clipPath: 'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)'}}></div></div>      

        <div className="gx uh arq asw auu chd cwk det dfb">
          <div className="gx uc uw cus cza dhd">
            <h1 className="kw avz awj axd text-amber-500 cjc">Toto Slot Thailand Situs Slot Gacor Slot Gampang JP Mudah Maxwin</h1>
            <p className="lk awf awx axu">SLOT THAILAND situs slot gacor reskomendasi idn slot terbesar di indonesia dengan server thailand mudah maxwin hari ini, menyediakan bocoran slot online dengan winrate 98% terpercaya pasti maxwin!!</p>
            <div className="kw lx zg aap">
              <a href="https://rebrand.ly/skh2s8h?pg=register" className="aeb bg-yellow-500 arm asc awg awm text-gray-900 bbt hover:bg-amber-500 bpb bpc bpe bpj">Daftar &amp; Mainkan</a>
              <a href="https://direct.lc.chat/15892908/" className="awg awm awv ban">Bantuan Live Chat <span aria-hidden="true">→</span></a>
            </div>
          </div>
          <div className="gx la lx uc byf cvk cvt cvu cyy cze dju">
            <div className="ud uv car cyy">
              <img src={cover} alt="JatengToto Slot Gacor" width="2432" height="1442" className="tm aeb alw bbp bbz bds" />
            </div>
          </div>
        </div>
        <footer className="aje" aria-labelledby="footer-heading"><div className="gx uh arq ate avm det"><div className="aft ahh avm avr"><p className="awf awu axv cky cmf">&copy; 2024 JatengToto. All rights reserved.</p></div></div></footer>
      </div>
    </div>
  );
}

export default App;
